import api from "../api"

class PostsService {

	static getPosts(page, dates) {
		return api({
			params: { page, dates },
			url: 'posts'
		})
	}

	static storePost(data) {
		return api({
			method: 'post',
			data: data,
			url: 'posts'
		})
	}

	static updatePost(data) {
		return api({
			method: 'post',
			data: data,
			url: 'posts'
		})
	}

	static deletePost(data) {
		return api({
			method: 'delete',
			data: data,
			url: 'posts'
		})
	}

}

export default PostsService
