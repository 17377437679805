<template>
  <div>
    <div class="row">
      <div class="col d-flex justify-content-between">
        <vs-breadcrumb
        :items="items"
        ></vs-breadcrumb>
        <div>
          <vs-button icon="add" @click="open = true" type="gradient">Crear nuevo</vs-button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <vs-card>
          <vs-table stripe :data="posts">
            <template slot="header">
              <h4>Publicaciones</h4>
            </template>
            <template slot="thead">
              <vs-th>
                Fecha de publicación
              </vs-th>
              <vs-th>
                Titulo
              </vs-th>
              <vs-th>
                Contenido
              </vs-th>
              <vs-th>
                Acciones
              </vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr class="text-left" :key="indextr" v-for="(tr, indextr) in data" >
                <vs-td>
                  {{ tr.created_at }}
                </vs-td>

                <vs-td>
                  {{ tr.title }}
                </vs-td>

                <vs-td>
                  {{ tr.content_truncate }}
                </vs-td>

                <vs-td>
                  <div class="d-flex">
                    <vs-button icon="create" @click="editPost(tr)" class="mr-2" color="primary" type="gradient">Editar</vs-button>
                    <vs-button icon="delete_sweep" @click="deletePost(tr.id, indextr)" color="danger" type="gradient">Eliminar</vs-button>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div>
            <vs-pagination class="mt-2" :total="total_pages" v-model="current_page"></vs-pagination>
          </div>
        </vs-card>
      </div>
    </div>

    <vs-popup classContent="popup-example" :title="edit ? 'Editar post' : 'Registrar post'" :active.sync="open">

      <div>
        <span v-show="errors.has('title')" class="w-100 text-danger mt-2">
          {{ errors.first("title") }}
        </span> <br>
        <label>Titulo del post *</label>
        <vs-input data-vv-as="Titulo del post" name="title" v-validate="'required'" v-model="post.title" class="inputx w-100"/>
      </div>

      <div class="mt-2">
        <span v-show="errors.has('content')" class="w-100 text-danger mt-2">
          {{ errors.first("content") }}
        </span><br>
        <label>Contenido del post</label>
        <vue-editor data-vv-as="Contenido del post" name="content" v-validate="'required'" v-model="post.content" :editorToolbar="customToolbar"/>
      </div>

      <div class="row mt-2">
        <div class="col">
          <div class="button-wrapper">
            <span class="label">
              {{ file_name }} <span v-if="!edit">*</span>
            </span>
            <input data-vv-as="Imagen" name="image" v-validate="!edit ? 'required' : ''" @change="handleFile" type="file" id="upload" class="upload-box" placeholder="Subir imagenes del producto">
          </div>
          <span v-show="errors.has('image')" class="w-100 text-danger mt-2">
            {{ errors.first("image") }}
          </span>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col d-flex justify-content-between">
          <div>
            <vs-button icon="save" :disabled="loading" v-if="edit" class="mt-5" @click="updatePost" color="primary" type="gradient">Editar</vs-button>
            <vs-button icon="save" :disabled="loading" v-else class="mt-5" @click="storePost" color="primary" type="gradient">Guardar</vs-button>
          </div>
        </div>
      </div>

    </vs-popup>

  </div>
</template>

<script>
import PostService from '@/services/posts.service'
import { VueEditor } from "vue2-editor";

export default {
  components: { VueEditor },
  data() {
    return {
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }],
        ["clean"]
      ],
      posts: [],
      open: false,
      loading: false,
      edit: false,
      current_page: 1,
      total_pages: 1,
      file_name: 'Seleccionar imagen',
      dates: [],
      items: [
        {
          title: 'Admin Tag Store',
          url: '/'
        },
        {
          title: 'Posts',
          active: true
        }
      ],
      post: {
        title: '',
        content: '',
        image: ''
      }
    }
  },
  watch: {
    open(newVal) {
      if (!newVal) {
        this.edit = false
        this.post = JSON.parse(JSON.stringify(this.$options.data().post))
        this.file_name = ''
      }
    },
    current_page(newval) {
      this.getPosts()
    }
  },
  methods: {
    async storePost() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          try {
            this.loading = true

            const formData = new FormData();

            formData.append('image', this.post.image)
            formData.append('title', this.post.title)
            formData.append('content', this.post.content)

            const { data:res } = await PostService.storePost(formData)

            if (res.success) {
              this.open = false
              this.posts.unshift(res.data)
              this.$vs.notify({
                title: res.message,
                text:'',
                color: 'success',
                position: 'top-right'
              })
            }

          } catch(e) {
            this.$swal('Estamos teniendo problemas', '', 'warning')
          }
          this.loading = false
        }
      })
    },
    async updatePost() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          try {
            this.loading = true

            const formData = new FormData();

            formData.append('id', this.post.id)
            formData.append('image', this.post.image)
            formData.append('title', this.post.title)
            formData.append('content', this.post.content)
            formData.append('_method', 'put')

            const { data:res } = await PostService.updatePost(formData)

            if (res.success) {
              this.open = false
              const index = this.posts.findIndex(i => i.id == res.data.id)
              this.posts[index] = res.data
              this.posts = JSON.parse(JSON.stringify(this.posts))
              this.$vs.notify({
                title: res.message,
                text:'',
                color: 'success',
                position: 'top-right'
              })
            }

          } catch(e) {
            this.$swal('Estamos teniendo problemas', '', 'warning')
          }
          this.loading = false
        }
      })
    },
    async getPosts() {
      this.$vs.loading({
        type: 'point'
      })
      const {data:res} = await PostService.getPosts(this.current_page, this.dates)
      this.posts = res.data.data
      this.total_pages = res.data.last_page
      this.$vs.loading.close()
    },
    editPost(item) {
      this.post = JSON.parse(JSON.stringify(item))
      this.edit = true
      this.open = true
    },
    deletePost(id, index) {
      this.$confirm(
        {
          message: `Seguro de eliminar la imagen?`,
          button: {
            no: 'No',
            yes: 'Si'
          },
          callback: async confirm => {
            if (confirm) {
              const { data:res } = await PostService.deletePost({
                id: id
              })
              if (res.success) {
                this.posts.splice(index, 1)
              } else {
                this.$swal(res.message, '', 'warning')
              }
            }
          }
        }
      )
    },
    handleFile(event) {
      this.file_name = event.target.files[0].name
      this.post.image = event.target.files[0]
    }
  },
  created() {
    this.getPosts()
  }
}
</script>
